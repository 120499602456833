/* eslint-disable @next/next/no-html-link-for-pages --
 * The /logout and /account paths interact w/ Auth0 middleware on the server
 * that makes next/link prefetching problematic.
 */
import { useUser } from "@auth0/nextjs-auth0/client";
import classNames from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Cookies, withCookies } from "react-cookie";

import { baseEnvironmentConfig } from "@/api/constants";
import DownArrow from "@/assets/icons/down-arrow-light.svg";
import RightArrow from "@/assets/icons/right-arrow-light.svg";
// import AuthorizationLink from "@/components/AuthorizationLink";
import {
  COOKIES,
  IMAGE_ASSETS_CDN_PATH,
  SESSION_STORAGE_KEYS,
} from "@/constants";
import { removeItem } from "@/utils/helpers/localStorage";

import styles from "./GlobalHeader.module.scss";
import MobileHeaderButton from "./MobileHeaderButton";

const GlobalHeader = ({
  cookies,
  showSimpleHeaderTagline = true,
  useSimpleHeader = false,
}) => {
  const [documentUrl, setDocumentUrl] = useState("");
  const [expandedSubNav, setExpandedSubNav] = useState("");
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { user } = useUser();

  const selectedClubId = cookies.get(COOKIES.SELECTED_CLUB_ID.name) || "";
  const joinStepPath = selectedClubId
    ? `/join/step1/${selectedClubId}`
    : "/join/step1";

  useEffect(() => {
    setDocumentUrl(encodeURIComponent(document?.URL));
  }, []);

  const signOut = () => {
    removeItem(SESSION_STORAGE_KEYS.USER_SESSION);
    removeItem(baseEnvironmentConfig.localStorageItems.loginRedirectUrl);
    removeItem(baseEnvironmentConfig.localStorageItems.giftCardPayload);
  };

  useEffect(() => {
    document.body.setAttribute("data-has-open-nav", isMobileOpen);

    return () => {
      document.body.setAttribute("data-has-open-nav", false);
    };
  }, [isMobileOpen]);

  return (
    <header
      className={classNames("global-header", styles.globalHeader)}
      data-is="GlobalHeader"
      role="banner"
    >
      {useSimpleHeader ? (
        <div className={styles.content}>
          {showSimpleHeaderTagline ? (
            <h2 className={styles.equinoxTagline}>
              Equinox. It’s not fitness. It’s Life.
            </h2>
          ) : (
            <h2 className={styles.equinox}>Equinox</h2>
          )}
        </div>
      ) : (
        <nav className={styles.content} role="navigation">
          <Link
            className={styles.equinox}
            data-track="global-header:equinox"
            href="/"
            prefetch={false}
            title="It's not fitness. It's life."
          >
            <h2>Equinox</h2>
          </Link>

          {!user && (
            <Link
              className={styles.visitAClubCta}
              data-track="global-header:mobile-lead-flow"
              href="/membership"
            >
              Visit a Club
            </Link>
          )}

          <MobileHeaderButton
            className={styles.showMenuButton}
            onClick={() => {
              setIsMobileOpen(!isMobileOpen);
            }}
            title="Show navigation menu"
          />

          <ul className={styles.mainNav}>
            <li
              className={styles.mainNavItem}
              key="clubs"
              onMouseEnter={() => setExpandedSubNav("")}
              title="Clubs"
            >
              <Link
                className={styles.mainNavLink}
                data-track="global-header:clubs"
                href="/clubs?icmp=topnav-clubs"
                prefetch={false}
              >
                Clubs
              </Link>
            </li>

            <li
              className={styles.mainNavItem}
              key="member-benefits"
              onMouseEnter={() => setExpandedSubNav("")}
              title="Member Benefits"
            >
              <Link
                className={styles.mainNavLink}
                data-track="global-header:member-benefits"
                href="/memberbenefits?icmp=topnav-memberbenefits"
                prefetch={false}
              >
                Member Benefits
              </Link>
            </li>

            <li className={styles.mainNavItem} key="classes" title="Classes">
              <button
                aria-controls="classes-subnav-menu"
                aria-expanded={expandedSubNav === "Classes"}
                aria-haspopup="true"
                className={styles.subNavTitle}
                data-track="global-header:classes"
                name="GlobalHeader - Classes"
                onMouseEnter={() => setExpandedSubNav("Classes")}
              >
                Classes{" "}
                <DownArrow
                  alt="Classes"
                  className={styles.caret}
                  role="presentation"
                />
              </button>

              <ul
                className={styles.subNav}
                id="classes-subnav-menu"
                onMouseLeave={() => setExpandedSubNav("")}
              >
                <li className={styles.subNavItem}>
                  <Link
                    className={styles.subNavLink}
                    data-track="global-header:group-fitness"
                    href="/groupfitness?icmp=topnav-classes"
                    prefetch={false}
                    title="Group Fitness"
                  >
                    <img
                      alt="Group Fitness"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/group-fitness.jpg`}
                    />
                    Group Fitness{" "}
                    <RightArrow
                      alt="Group Fitness"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </Link>
                </li>

                <li className={styles.subNavItem}>
                  <Link
                    className={styles.subNavLink}
                    data-track="global-header:digital-classes"
                    href="/landing/eqx+"
                    prefetch={false}
                    title="Digital Classes"
                  >
                    <img
                      alt="Digital Classes"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/digital-classes.jpg`}
                    />
                    Digital Classes{" "}
                    <RightArrow
                      alt="Digital Classes"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </Link>
                </li>
              </ul>
            </li>

            <li className={styles.mainNavItem} key="training" title="Training">
              <button
                aria-controls="training-subnav-menu"
                aria-expanded={expandedSubNav === "Training"}
                aria-haspopup="true"
                className={styles.subNavTitle}
                data-track="global-header:training"
                name="GlobalHeader - Training"
                onMouseEnter={() => setExpandedSubNav("Training")}
              >
                Training{" "}
                <DownArrow
                  alt="Training"
                  className={styles.caret}
                  role="presentation"
                />
              </button>

              <ul
                className={styles.subNav}
                id="training-subnav-menu"
                onMouseLeave={() => setExpandedSubNav("")}
              >
                <li className={styles.subNavItem}>
                  <Link
                    className={styles.subNavLink}
                    data-track="global-header:personal-training"
                    href="/personaltraining?icmp=topnav-personaltraining"
                    prefetch={false}
                    title="Personal Training"
                  >
                    <img
                      alt="Personal Training"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/personal-training.jpg`}
                    />
                    Personal Training{" "}
                    <RightArrow
                      alt="Personal Training"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </Link>
                </li>

                <li className={styles.subNavItem}>
                  <Link
                    className={styles.subNavLink}
                    data-track="global-header:pilates"
                    href="/pilates?icmp=topnav-pilates"
                    prefetch={false}
                    title="Pilates"
                  >
                    <img
                      alt="Pilates"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/pilates.jpg`}
                    />
                    Pilates{" "}
                    <RightArrow
                      alt="Pilates"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={styles.mainNavItem}
              key="spa"
              onMouseEnter={() => setExpandedSubNav("")}
              title="Spa"
            >
              {/* <AuthorizationLink
                className={styles.mainNavLink}
                href={process.env.NEXT_PUBLIC_SPA_BOOKING_HOST}
                title="Spa"
              >
                Spa
              </AuthorizationLink> */}
              <Link
                className={styles.mainNavLink}
                data-track="global-header:spa"
                href={`${process.env.NEXT_PUBLIC_SPA_BOOKING_HOST}/?referrer=${documentUrl}`}
                prefetch={false}
              >
                Spa
              </Link>
            </li>

            <li
              className={styles.mainNavItem}
              key="hotels"
              onMouseEnter={() => setExpandedSubNav("")}
              title="Hotels"
            >
              <a
                aria-label="Equinox Hotels (Opens in a new window)"
                className={styles.mainNavLink}
                data-track="global-header:hotels"
                href="https://equinox-hotels.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Hotels
              </a>
            </li>

            <li className={styles.mainNavItem} key="shop" title="Shop">
              <button
                aria-controls="shop-subnav-menu"
                aria-expanded={expandedSubNav === "Shop"}
                aria-haspopup="true"
                className={styles.subNavTitle}
                data-track="global-header:shop"
                name="GlobalHeader - Shop"
                onMouseEnter={() => setExpandedSubNav("Shop")}
              >
                Shop{" "}
                <DownArrow
                  alt="Shop"
                  className={styles.caret}
                  role="presentation"
                />
              </button>

              <ul
                className={styles.subNav}
                id="shop-subnav-menu"
                onMouseLeave={() => setExpandedSubNav("")}
              >
                <li className={styles.subNavItem}>
                  <a
                    className={styles.subNavLink}
                    data-track="global-header:shop-equinox"
                    href="https://shop.equinox.com/?icmp=topnav-shop"
                    rel="nooppener noreferrer"
                    target="_blank"
                    title="The Shop at Equinox"
                  >
                    <img
                      alt="The Shop at Equinox"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/eqx-the-shop.jpg`}
                    />
                    The Shop at Equinox{" "}
                    <RightArrow
                      alt="The Shop at Equinox"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </a>
                </li>

                <li className={styles.subNavItem}>
                  <a
                    className={styles.subNavLink}
                    data-track="global-header:bike"
                    href="https://www.equinoxplus.com/bike"
                    rel="nooppener noreferrer"
                    target="_blank"
                    title="SoulCycle At-Home Bike"
                  >
                    <img
                      alt="SoulCycle At-Home Bike"
                      className={styles.subNavImage}
                      loading="lazy"
                      src={`${IMAGE_ASSETS_CDN_PATH}/at-home-soul-cycle-bike.jpg`}
                    />
                    SoulCycle At-Home Bike{" "}
                    <RightArrow
                      alt="SoulCycle At-Home Bike"
                      className={styles.rightArrow}
                      role="presentation"
                    />
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={styles.mainNavItem}
              key="alumni"
              onMouseEnter={() => setExpandedSubNav("")}
              title="Alumni"
            >
              <Link
                className={styles.mainNavLink}
                data-track="global-header:alumni"
                href="/promotion/former-member"
                prefetch={false}
              >
                Alumni
              </Link>
            </li>

            {user ? (
              <>
                <li
                  className={styles.mainNavItem}
                  key="sign-out"
                  onMouseEnter={() => setExpandedSubNav("")}
                  title="Sign out"
                >
                  <a
                    className={styles.mainNavLink}
                    data-track="global-header:sign-out"
                    href="/api/auth/logout"
                    onClick={signOut}
                  >
                    Sign out
                  </a>
                </li>

                <li
                  className={styles.mainNavItem}
                  key="account"
                  onMouseEnter={() => setExpandedSubNav("")}
                  title="Account"
                >
                  <Link
                    className={styles.mainNavLink}
                    data-track="global-header:account"
                    href="/account"
                    prefetch={false}
                  >
                    Account
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={styles.mainNavItem}
                  key="sign-in"
                  onMouseEnter={() => setExpandedSubNav("")}
                  title="Sign in"
                >
                  <a
                    className={styles.mainNavLink}
                    data-track="global-header:sign-in"
                    href="/account"
                  >
                    Sign in
                  </a>
                </li>

                <li
                  className={styles.mainNavItem}
                  data-shape="cta"
                  data-theme="light"
                  key="membership"
                  onMouseEnter={() => setExpandedSubNav("")}
                  title="Visit a Club"
                >
                  <Link
                    data-track="global-header:lead-flow"
                    href="/membership"
                    prefetch={false}
                  >
                    Visit a Club
                  </Link>
                </li>

                <li
                  className={styles.mainNavItem}
                  data-shape="cta"
                  data-theme="dark"
                  key="join"
                  onMouseEnter={() => setExpandedSubNav("")}
                  title="Join Online"
                >
                  <Link
                    data-track="global-header:join-flow"
                    href={joinStepPath}
                    prefetch={false}
                  >
                    Join Online
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

GlobalHeader.propTypes = {
  cookies: PropTypes.instanceOf(Cookies),
  showSimpleHeaderTagline: PropTypes.bool,
  useSimpleHeader: PropTypes.bool,
};

export default withCookies(GlobalHeader);
